import React from "react";
import { Button, Popup } from "semantic-ui-react";

const ActionButtonGroup = ({ id, onDelete, onEdit }) => (
  <Button.Group basic size="small" style={{ marginLeft: "5px" }}>
    <Popup
      content="Edit"
      trigger={<Button style={{ marginRight: "3px" }} icon="edit outline" onClick={() => onEdit(id)} />}
    />
    <Popup
      content="Delete"
      trigger={<Button style={{ marginLeft: "5px" }} icon="delete" onClick={() => onDelete(id)} />}
    />
  </Button.Group>
);

export default ActionButtonGroup;
