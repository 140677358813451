import React, { useEffect, useState } from "react";
import { Table, Modal } from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

// Components
import ComposeDrinkLayout from "../Layouts/ComposeDrinkLayout";

// Actions
import { openFormModal } from "../../redux/modals/modals.actions";
import { getDrinks, editDrinkModal, requestdrinks, secondaryDrinkModal  } from "../../redux/drinks/drinks.actions";

// Form Constants
import form from "../../redux/modals/constants";

import EditedDrinkIngredient from "./editedDrinkIngredient";
import axiosInstance from "../../axios";
import SecondaryDrink from "./secondaryDrink";

const EditDrinkComponent = () => {
    const { cartridgeType } = useSelector((state) => state);
    const { drinks } = useSelector((state) => state);
    const dispatch = useDispatch();
    const [state, setState] = useState({
        ...drinks.editingDrink
    })
    const [Errors, setErrors] = useState({
        noName: false
    })
    const [mode, setMode] = useState('ml')
    const [hw, setHw] = useState('V4')
    const [saving, setSaving] = useState(false)
    const [missingErrors, setMissingErrors] = useState(false)

    const { loading, error } = cartridgeType;

    useEffect(() => {
        setState((prevState) => {
            return {
                ...drinks.editingDrink,
            }
        })
    }, []);

    const orderDrinkItemsByIndex = (data, currentIndex) => {
        // Increment the index of the item being moved by 1 and put it in the new index
        console.log(data, currentIndex)
        let ingredients = state.ingredients
        const filtered = ingredients.filter((item) => {
            return item._id !== data._id
        }
        )
        filtered.splice(currentIndex, 0, data)
        setState((prevState) => {
            return {
                ...prevState,
                ingredients: filtered
            }
        }
        )

    }

    const setDrinkItems = (data) => {
        delete data._rev
        let ingredients = state.ingredients
        const filtered = ingredients.filter((item) => {
            return item._id !== data._id
        })
        filtered.push(data)
        setState((prevState) => {
            return {
                ...prevState,
                ingredients: filtered
            }
        })
    }
    const removeFromDrink = (data) => {
        let ingredients = state.ingredients
        const filtered = ingredients.filter((item) => {
            return item._id !== data._id
        })
        setState((prevState) => {
            return {
                ...prevState,
                ingredients: filtered
            }
        })
    }

    if (error) {
        toast.error(error);
    }

    let renderCartridgeType;

    if (drinks.editingDrink) {
        if(!drinks.editingDrink?.ingredients) {
          drinks.editingDrink.ingredients = []
        }
        var ids = new Set(drinks.editingDrink?.ingredients?.map(d => d._id)) || new Set();
        const filtered = [...drinks.editingDrink.ingredients, ...cartridgeType.cartridgeTypes.filter(d => !ids.has(d._id))]
        renderCartridgeType = filtered.map((value, index) => {

            return (
                <EditedDrinkIngredient orderDrinkItemsByIndex={orderDrinkItemsByIndex} index={index} mode={mode} key={value._id} value={value} setDrinkItems={setDrinkItems} removeFromDrink={removeFromDrink} hw={hw} missingErrors={missingErrors}/>
            );
        });
    } else {
        renderCartridgeType = (
            <Table.Row>
                <Table.Cell>No cartridge types found.</Table.Cell>
            </Table.Row>
        );
    }

    const onChangeName = (name) => {
        setState((prevState) => {
            return {
                ...prevState,
                name
            }
        })
    }
    const onChangeDisplayPriority = (displayPriority) => {
      setState((prevState) => {
        return {
          ...prevState,
          displayPriority: parseInt(displayPriority) || 1,
        };
      });
    };
    const onChangeMode = (e, mode) => {
        setMode(mode.value)
    }
    const onChangeHwVersion = (e, mode) => {
        setHw(mode.value)
    }
    const validateDrinkIngredientIntensity = (data) => {
      const result = data.ingredients.find((item) => {
        const intensities = item.intensities;
        
        if (item.inDrink && item.isBoost) {
          return (
            intensities.ml.V4.intensity1 === 0 ||
            intensities.ml.V4.intensity2 === 0 ||
            intensities.ml.V4.intensity3 === 0 ||
            intensities.ml.V4.boostLevel1 === 0 ||
            intensities.ml.V4.boostLevel2 === 0 ||
            intensities.ml.V4.boostLevel3 === 0
          );
        } else if (item.inDrink) {
          return (
            intensities.ml.V4.intensity1 === 0 ||
            intensities.ml.V4.intensity2 === 0 ||
            intensities.ml.V4.intensity3 === 0
          );
        } else if (item.isBoost) {
          return (
            intensities.ml.V4.boostLevel1 === 0 ||
            intensities.ml.V4.boostLevel2 === 0 ||
            intensities.ml.V4.boostLevel3 === 0
          );
        }
        return false
      });
      
      if(result) {
        setMissingErrors(true)
      }
      return result ? false : true
    }
    const saveDrink = async (name) => {
      if(saving) {
        console.log("Saving....")
        return
      }
        setSaving(true)
        const convertToNumber = {
            ...state,
            ingredients: state.ingredients.map((item) => {
                return {
                  ...item,
                  intensity1: Number(item.intensity1),
                  intensity2: Number(item.intensity2),
                  intensity3: Number(item.intensity3),
                  boostLevel1: Number(item.boostLevel1),
                  boostLevel2: Number(item.boostLevel2),
                  boostLevel3: Number(item.boostLevel3),
                  intensities: {
                    ml: {
                      V4: {
                        boostLevel1: Number(item.intensities?.ml?.V4?.boostLevel1) || 0,
                        boostLevel2: Number(item.intensities?.ml?.V4?.boostLevel2) || 0,
                        boostLevel3: Number(item.intensities?.ml?.V4?.boostLevel3) || 0,
                        intensity1: Number(item.intensities?.ml?.V4?.intensity1) || 0,
                        intensity2: Number(item.intensities?.ml?.V4?.intensity2) || 0,
                        intensity3: Number(item.intensities?.ml?.V4?.intensity3) || 0,
                      },
                      boostLevel1: Number(item.intensities?.ml?.boostLevel1) || 0,
                      boostLevel2: Number(item.intensities?.ml?.boostLevel2) || 0,
                      boostLevel3: Number(item.intensities?.ml?.boostLevel3) || 0,
                      intensity1: Number(item.intensities?.ml?.intensity1) || 0,
                      intensity2: Number(item.intensities?.ml?.intensity2) || 0,
                      intensity3: Number(item.intensities?.ml?.intensity3) || 0,
                    },
                    ms: {
                      V4: {
                        boostLevel1: Number(item.intensities?.ms?.V4?.boostLevel1) || 0,
                        boostLevel2: Number(item.intensities?.ms?.V4?.boostLevel2) || 0,
                        boostLevel3: Number(item.intensities?.ms?.V4?.boostLevel3) || 0,
                        intensity1: Number(item.intensities?.ms?.V4?.intensity1) || 0,
                        intensity2: Number(item.intensities?.ms?.V4?.intensity2) || 0,
                        intensity3: Number(item.intensities?.ms?.V4?.intensity3) || 0,
                      },
                      boostLevel1: Number(item.intensities?.ms?.boostLevel1) || 0,
                      boostLevel2: Number(item.intensities?.ms?.boostLevel2) || 0,
                      boostLevel3: Number(item.intensities?.ms?.boostLevel3) || 0,
                      intensity1: Number(item.intensities?.ms?.intensity1) || 0,
                      intensity2: Number(item.intensities?.ms?.intensity2) || 0,
                      intensity3: Number(item.intensities?.ms?.intensity3) || 0,
                    },
                  },
                };
            })
        }
        const isValid = validateDrinkIngredientIntensity(convertToNumber)
        if (!isValid) {
          toast.error("Some ingredients have 0 ml intensity")
          setSaving(false)
          return
        }
        if (!state.name) {
            setErrors((prevState) => {
                return {
                    ...prevState,
                    noName: true
                }
            })
        } else {
            console.log(convertToNumber);
            // order state.ingredients by its rank property, if no rank property, put thos with rank first then others
            const ordered = convertToNumber.ingredients.sort((a, b) => {
              if (a.rank && b.rank) {
                return a.rank - b.rank;
              } else if (a.rank) {
                return -1;
              } else if (b.rank) {
                return 1;
              } else {
                return 0;
              }
            });
            const orderedState = {
              ...convertToNumber,
              ingredients: ordered,
            };


            dispatch(requestdrinks())
            const data = await axiosInstance.post("/drinks/edit", {
              id: convertToNumber._id,
              data: orderedState,
            });
            dispatch(getDrinks());
            dispatch(editDrinkModal())
            setSaving(false)
        }
    }
    const onSaveData = (data) => {
        setState((prevState) => {
          return {
            ...prevState,
            ...data
          }
        })
        dispatch(secondaryDrinkModal())
        console.log(state)
      }
    const toggleStatus = (status) => {
        setState((prevState) => {
            return {
                ...prevState,
                status
            }
        })
        console.log(status)
    }
    const setdescImage = (value) => {
        setState((prevState) => {
          return {
            ...prevState,
            descriptionsImage: value
          }
        })
        console.log(value)
      }
      const setBtnImage = (value) => {
        setState((prevState) => {
          return {
            ...prevState,
            image: value
          }
        })
        console.log(value)
      }
    return (
        <ComposeDrinkLayout
            onChangeHwVersion={onChangeHwVersion}
            onChangeMode={onChangeMode}
            title="Edit Drink"
            loading={loading}
            openAddModal={() => dispatch(openFormModal(form.NEW_DRINK))}
            onChangeName={onChangeName}
            saveDrink={saveDrink}
            setStatus={toggleStatus}
            setBtnImage={setBtnImage}
            setdescImage={setdescImage}
            secondaryDrinkModal={() => dispatch(secondaryDrinkModal())}
            onChangeDisplayPriority={onChangeDisplayPriority}
        >
                  <Modal
        size={"medium"}
        open={drinks.secondaryModal}
        onClose={() => dispatch(secondaryDrinkModal())}
      >
        <SecondaryDrink onSaveData={onSaveData} />
      </Modal>
            <Table basic="very" celled collapsing size="small" compact>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.HeaderCell>Id</Table.HeaderCell>
                        <Table.HeaderCell>Type</Table.HeaderCell>
                        <Table.HeaderCell>Order</Table.HeaderCell>
                        <Table.HeaderCell>In Drink?</Table.HeaderCell>
                        <Table.HeaderCell>Intensity 1</Table.HeaderCell>
                        <Table.HeaderCell>Intensity 2</Table.HeaderCell>
                        <Table.HeaderCell>Intensity 3</Table.HeaderCell>
                        <Table.HeaderCell>Is Boost?</Table.HeaderCell>
                        <Table.HeaderCell>Boost Level1</Table.HeaderCell>
                        <Table.HeaderCell>Boost Level2</Table.HeaderCell>
                        <Table.HeaderCell>Boost Level3</Table.HeaderCell>
                        <Table.HeaderCell>Default boost Level</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>{renderCartridgeType}</Table.Body>
            </Table>
        </ComposeDrinkLayout>
    );
};

export default EditDrinkComponent;
