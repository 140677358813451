import React, { useEffect, useState } from "react";
import { Header, Table, Button, Modal } from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

// Components
import DashboardContentLayout from "../Layouts/DashboardContentLayout";
import ActionButtonGroup from "../Common/ActionButtonGroup";

// Actions
import {
  getDrinks,
  toggleDrinkModal,
  deleteDrink,
  editDrink,
  editDrinkModal,
} from "../../redux/drinks/drinks.actions";

// Form Constants
import ComposeDrink from "./composeDrink";
import EditDrink from "./editDrink";

const DrinksComponent = () => {
  const dispatch = useDispatch();
  const [drinkTags, setDrinkTags] = useState(["production", "testing", "h2yo_office"]);
  const { drinks } = useSelector((state) => state);
  const [localPriorities, setLocalPriorities] = useState({});

  const { loading, error } = drinks;

  const fetchDrinkTags = async () => {
    try {
      const response = await fetch("https://api.h2yo.co/api:gigbvQ5J/get_drink_tags");
      if (!response.ok) {
        console.log("Failed to fetch drink tags");
      }
      const data = await response.json();
      if (Array.isArray(data) && data.length > 0) {
        setDrinkTags(data);
      }
    } catch (error) {
      console.error("Error fetching drink tags:", error);
    }
  };

  const onDeleteDrink = (id) => {
    const confirm = window.confirm("Are you sure you want to delete this drink?");
    if (confirm) {
      dispatch(deleteDrink(id));
    }
  };

  const onEdit = (drink) => {
    dispatch(editDrinkModal(drink));
  };
  const editStatus = (value) => {
    dispatch(editDrink(value._id, { status: !value.status }));
  };
  const editAllowNoIngredients = (value) => {
    dispatch(editDrink(value._id, { allowNoIngredients: !value.allowNoIngredients }));
  };

  const editDisplayPriority = (drinkId, newPriority) => {
    setLocalPriorities(prev => ({
      ...prev,
      [drinkId]: newPriority
    }));
  };

  const handlePriorityChange = (drinkId, currentPriority, newValue) => {
    const numValue = parseInt(newValue, 10);
    if (!isNaN(numValue) && numValue !== currentPriority) {
      dispatch(editDrink(drinkId, { displayPriority: numValue }));
    }
  };

  const handleAddTag = (drinkId, newTag) => {
    const drink = drinks.drinkList.find(d => d._id === drinkId);
    if (drink) {
      if (!drink.tags || !drink.tags.includes(newTag)) {
        const updatedTags = [...(drink.tags || []), newTag];
        dispatch(editDrink(drinkId, { tags: updatedTags }));
      }
    }
  };

  const handleRemoveTag = (drinkId, tagToRemove) => {
    const drink = drinks.drinkList.find(d => d._id === drinkId);
    if (drink && drink.tags) {
      const updatedTags = drink.tags.filter(tag => tag !== tagToRemove);
      dispatch(editDrink(drinkId, { tags: updatedTags }));
    }
  };

  useEffect(() => {
    dispatch(getDrinks());
    fetchDrinkTags();
  }, [dispatch]);

  if (error) {
    toast.error(error);
  }

  let renderCartridgeType;

  if (drinks.drinkList && drinks.drinkList.length) {
    renderCartridgeType = drinks.drinkList
      .sort((a, b) => {
        const priorityA = a.displayPriority ?? 0;
        const priorityB = b.displayPriority ?? 0;
        return priorityB - priorityA;
      })
      .map((value) => {
        return (
          <Table.Row key={value._id}>
            <Table.Cell>
              <Header as="h4" image>
                <Header.Content>{value.name}</Header.Content>
              </Header>
            </Table.Cell>
            <Table.Cell style={{ maxWidth: "200px" }}>
              <div
                style={{
                  marginBottom: "5px",
                  display: "flex",
                  flexWrap: "wrap",
                  width: "100%",
                }}
              >
                {value.tags?.map((tag, index) => (
                  <span
                    key={index}
                    style={{
                      marginRight: "5px",
                      marginBottom: "3px",
                      padding: "2px 5px",
                      backgroundColor: "#e8e8e8",
                      borderRadius: "3px",
                      cursor: "pointer",
                      display: "inline-block",
                    }}
                    onClick={() => handleRemoveTag(value._id, tag)}
                    title="Click to remove tag"
                  >
                    {tag} ×
                  </span>
                ))}
              </div>
              <select
                onChange={(e) => {
                  const newTag = e.target.value;
                  if (newTag) {
                    handleAddTag(value._id, newTag);
                    e.target.value = "";
                  }
                }}
                value=""
                style={{
                  padding: "4px 8px",
                  borderRadius: "4px",
                  border: "1px solid #ccc",
                  backgroundColor: "#f8f8f8",
                  fontSize: "0.9em",
                  cursor: "pointer",
                  color: "#444",
                  boxShadow: "0 1px 2px rgba(0,0,0,0.05)",
                }}
              >
                <option value="">Add tag...</option>
                {drinkTags.map((tag, index) => (
                  <option key={index} value={tag}>
                    {tag}
                  </option>
                ))}
              </select>
            </Table.Cell>
            <Table.Cell style={{ maxWidth: "150px", padding: "2px" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Button 
                  icon="minus" 
                  size="mini" 
                  onClick={() => {
                    const currentPriority = localPriorities[value._id] ? localPriorities[value._id] : value.displayPriority || 0;
                    const newPriority = Math.max(0, currentPriority - 1);
                    editDisplayPriority(value._id, newPriority);
                  }}
                />
                <input
                  type="number"
                  min="0"
                  value={(localPriorities[value._id] !== undefined) 
                    ? localPriorities[value._id] 
                    : (value.displayPriority || 0)}
                  style={{
                    width: "50px",
                    textAlign: "center",
                    margin: "0 5px"
                  }}
                />
                <Button 
                  icon="plus" 
                  size="mini" 
                  onClick={() => {
                    const currentPriority = localPriorities[value._id] ? localPriorities[value._id] : value.displayPriority || 0;
                    const newPriority = currentPriority + 1;
                    editDisplayPriority(value._id, newPriority);
                  }}
                />
              </div>
              <div style={{ marginTop: "5px", textAlign: "center" }}>
                <Button
                  size="mini"
                  compact
                  primary
                  style={{ fontSize: "0.8em", padding: "4px 8px" }}
                  onClick={() => {
                    if (localPriorities[value._id] !== undefined) {
                      handlePriorityChange(
                        value._id,
                        value.displayPriority || 0,
                        localPriorities[value._id]
                      );
                    }
                  }}
                  disabled={localPriorities[value._id] === undefined || 
                           localPriorities[value._id] === (value.displayPriority || 0)}
                >
                  Save
                </Button>
              </div>
            </Table.Cell>
            <Table.Cell>
              <div className="ui fitted toggle checkbox">
                <input
                  type="checkbox"
                  checked={value.allowNoIngredients}
                  onChange={() => editAllowNoIngredients(value)}
                />
                <label></label>
              </div>
            </Table.Cell>
            <Table.Cell>
              <Button
                toggle
                active={value.status}
                onClick={() => editStatus(value)}
              >
                {value.status ? "Active" : "TEST"}
              </Button>
            </Table.Cell>
            <Table.Cell>
              <ActionButtonGroup
                id={value._id}
                onDelete={onDeleteDrink}
                onEdit={() => onEdit(value)}
              />
            </Table.Cell>
          </Table.Row>
        );
      });
  } else {
    renderCartridgeType = (
      <Table.Row>
        <Table.Cell>No cartridge types found.</Table.Cell>
      </Table.Row>
    );
  }

  return (
    <DashboardContentLayout
      title="H2YO Drinks"
      loading={loading}
      openAddModal={() => dispatch(toggleDrinkModal())}
    >
      <Modal
        style={{
          width: "90%",
        }}
        size={"large"}
        open={drinks.drinkModal}
        onClose={() => dispatch(toggleDrinkModal())}
      >
        <ComposeDrink />
      </Modal>
      <Modal
        style={{
          width: "90%",
        }}
        size={"large"}
        open={drinks.editModal}
        onClose={() => dispatch(editDrinkModal())}
      >
        <EditDrink />
      </Modal>
      <Table basic="very" celled collapsing size="small" compact>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Tags</Table.HeaderCell>
            <Table.HeaderCell>Display Priority</Table.HeaderCell>
            <Table.HeaderCell>No Ingredients</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>Edit | Delete</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>{renderCartridgeType}</Table.Body>
      </Table>
    </DashboardContentLayout>
  );
};

export default DrinksComponent;
