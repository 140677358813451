import React, { useState } from "react";
import { useSelector } from 'react-redux';
import { Segment, Header, Divider, Button, Popup, Input, Dropdown } from "semantic-ui-react";

const modeOptions = [
    { key: 'Millisecond Ms', value: 'ms', text: 'Millisecond Ms' },
    { key: 'Millilitre Ml', value: 'ml', text: 'Millilitre Ml' }
  ]
const hwOptions = [
    { key: 'V2, V3 and V3.5', value: 'V3', text: 'V2, V3 and V3.5' },
    { key: 'V4', value: 'V4', text: 'V4' }
  ]

const ComposeDrinkLayout = ({ title, children, loading, saveDrink, onChangeName, onChangeDisplayPriority, secondaryDrinkModal, onChangeMode, onChangeHwVersion }) => {
    const { drinks } = useSelector((state) => state);
    const [state, setState] = useState({
        name: drinks.editingDrink ? drinks.editingDrink.name : '',
        status: drinks.editingDrink ? drinks.editingDrink.status : false,
        displayPriority: drinks.editingDrink ? drinks.editingDrink.displayPriority : 1
    })

    return (
      <>
        <Segment color="green" loading={loading}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            <Header as="h2">{title}</Header>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div style={{ ...StyleSheet.labelWrapper }}>
                <div style={{ ...StyleSheet.nameLabel }}>Name:</div>
                <Input
                  style={{ ...StyleSheet.input }}
                  placeholder="Raspberry city..."
                  value={state.name}
                  onChange={(e) => {
                    onChangeName(e.target.value);
                    setState({ ...state, name: e.target.value });
                  }}
                />
              </div>
              <div style={{ ...StyleSheet.labelWrapper }}>
                <div style={{ ...StyleSheet.nameLabel }}>HW Version:</div>
                <Dropdown
                  onChange={(e, val) => {
                    onChangeHwVersion(e, val);
                  }}
                  placeholder="Select Hw"
                  defaultValue="V4"
                  fluid
                  search
                  selection
                  options={hwOptions}
                />
              </div>
              <div style={{ ...StyleSheet.labelWrapper }}>
                <div style={{ ...StyleSheet.nameLabel }}>Mode:</div>
                <Dropdown
                  onChange={(e, val) => {
                    onChangeMode(e, val);
                  }}
                  placeholder="Select Mode"
                  defaultValue="ml"
                  fluid
                  search
                  selection
                  options={modeOptions}
                />
              </div>
              <div style={{ ...StyleSheet.labelWrapper }}>
                <div style={{ ...StyleSheet.nameLabel }}>Display priority:</div>
                <Input
                  style={{ ...StyleSheet.input, width: "80px" }}
                  placeholder="1"
                  value={state.displayPriority}
                  onChange={(e) => {
                    onChangeDisplayPriority(e.target.value);
                    setState({ ...state, displayPriority: e.target.value });
                  }}
                />
              </div>
              <div style={{ ...StyleSheet.labelWrapper }}>
                <div style={{ ...StyleSheet.nameLabel }}>Secondary Data:</div>
                <Popup
                  content="Drink images and misceleneous"
                  trigger={<Button icon="add" onClick={secondaryDrinkModal} />}
                />
              </div>
              <div style={StyleSheet.buttonsWrapper}>
                <Button toggle active={state.name} onClick={() => saveDrink()}>
                  Save
                </Button>
              </div>
            </div>
          </div>

          <Divider />
          <Segment.Inline>{children}</Segment.Inline>
        </Segment>
      </>
    );
};

const StyleSheet = {
    input: {
        width: "200px",
    },
    labelWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    nameLabel: {
        fontSize: '16px',
        marginRight: '10px',
        marginBottom: '10px'
    },
    buttonsWrapper: {
        display: "flex",
        flexDirection: 'row',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    DEPLOY: {
        marginRight: '25px'
    }
}

export default ComposeDrinkLayout;
